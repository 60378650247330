import React from 'react';
import {Button, Segment, Divider} from 'semantic-ui-react';

export default ({
  display_price: {
    with_tax: {formatted},
  },
}) => (
  <div>
    <Divider />
    <Segment clearing size="large">
      <span>
        <strong>總額</strong>
        {` ${formatted}`}
      </span>

      <Button
        color="black"
        floated="right"
        onClick={() => window.alert('結帳')}>
        結帳去！
      </Button>
    </Segment>
  </div>
);
